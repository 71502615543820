package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.FatCardSemantic

fun ViewWriter.showConfirmDialog(
    title: String,
    content: String,
    confirmLabel: String? = null,
    warning: String? = null,
    cancelButtonText: String = "Cancel",
    onConfirm: suspend () -> Unit
) {
    val overlay = overlayStack ?: return

    with(overlay) {
        dismissBackground {
            fun close() {
                overlay.removeChild(this)
            }
            onClick { close() }
            padded - stack {
                centered - FatCardSemantic.onNext - sizeConstraints(width = 32.rem) - expanding - col {
                    spacing = 2.rem
                    centered - h4(title)

                    text { ::content { content } }
                    danger - unpadded - text {
                        exists = false
                        ::exists { warning != null }
                        ::content { warning ?: "" }
                    }
                    stack {
                        ::exists { confirmLabel == null }
                        actionOrCancelSection(
                            cancelButtonText = shared { cancelButtonText },
                            onCancel = { close() },
                            actionButton = {}
                        )
                    }
                    stack {
                        ::exists { confirmLabel != null }
                        actionOrCancelSection(
                            cancelButtonText = shared { cancelButtonText },
                            onCancel = { close() },
                            actionButton = {
                                importantButton(shared { confirmLabel ?: "" }) {
                                    onConfirm()
                                    close()
                                }
                            }
                        )
                    }
                }
            }
        }
    }
}