package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.dismissBackground
import com.lightningkite.kiteui.views.direct.h4
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.expanding
import com.picme.FatCardSemantic


fun ViewWriter.smallDialog(title: String? = null, setup: ViewWriter.() -> Unit) {
    dismissBackground {
        centered - FatCardSemantic.onNext - sizeConstraints(maxWidth = 32.rem) - expanding - col {
            spacing = 2.rem
            centered - h4 {
                ::exists { title != null }
                content = title ?: ""
            }
            setup()
        }
    }
}