package com.picme.layout

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.views.*

fun ViewWriter.toolbar() {
    val showToolbar = shared {
        val curr = screenNavigator.currentScreen()
        if (curr is NoTopBar) return@shared false
        if (curr is IncludeTopBar) return@shared true

        if (curr is CollectionLanding) return@shared (session()?.isVerifiedAccount() == true)

        (curr !is UnauthScreen && curr !is PicmeDialog)
    }
    bar - row {
        ::exists { showToolbar() }
        spacing = 0.75.rem
        collectionListMenu()
        row {
            stack {
                val title = shared {
                    screenNavigator.currentScreen()?.title?.await() ?: "..."
                }
                gravity(Align.Start, Align.Center) - row {
                    ::exists { title().isNotEmpty() }
                    centered - h4 { ::content { title() } }
                }
                centered - stack {
                    ::exists{ title().isEmpty() }
                    picmeIconDisplay()
                }
            }
        }
        val actionSection = shared {
            if (isSmallScreen() || currentCollection() == null) ActionSection.None
            else if (view() == CollSubPage.Main) ActionSection.Collection
            else if (view() == CollSubPage.Trash) ActionSection.Trash
            else ActionSection.None
        }
        expanding - row {
            expanding - space {}
            ::exists { actionSection() != ActionSection.None }
            unpadded - stack {
                spacing = 0.rem
                swapView {
                    swapping(
                        current = { actionSection() },
                        views = {
                            when (it) {
                                ActionSection.Collection -> collectionNavButtons(collection = shared { currentCollection()!! }, false)
                                ActionSection.Trash -> trashActions(false)
                                ActionSection.None -> {}
                            }
                        },
                    )
                }
            }
        }
        expanding - space {
            ::exists { actionSection() == ActionSection.None }
        }
        userInfoPopover()
    }
    separatorTheme - separator { ::exists { showToolbar() } }
}

enum class ActionSection { Collection, Trash, None }