package com.picme.views

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.CreateCollection
import com.picme.components.PicmeDialog
import com.picme.components.actionButtonNoText
import com.picme.sdk2.SafeIds

class CollectionList : PicmeDialog() {
    private val showSearch = Property(false)
    override val title = Constant("Collections")
    override fun ViewWriter.topBar(close: suspend () -> Unit) = row {
        centered - actionButtonNoText(
            displayIcon = PIcon.chevronleft,
        ) { showSearch set false; close() }
        centered - h3 {
            ::content { title() }
        }
        expanding - space {}
        centered - actionButtonNoText(
            displayIcon = PIcon.add,
            onClick = {
                showSearch set false
                navigator.navigate(CreateCollection)
            }
        )
        centered - actionButtonNoText(displayIcon = PIcon.search) {
            showSearch set !showSearch()
        }
    }

    override fun ViewWriter.content() {
        expanding - collectionList(showSearch) { close() }
    }
}

fun ViewWriter.collectionList(showSearch: Writable<Boolean>, onCollectionSelect: suspend () -> Unit = {}) = col {
    val searchText = Property("")
    showSearch bind showSearch.withWrite { searchText set "" }

    val displayMyColls = Property(true)

    onlyWhen { showSearch() } - col {
        spacing = 0.5.rem
        padded - col {
            fieldTheme - row {
                spacing = 0.5.rem
                expanding - textField {
                    hint = "Search for a collection"
                    content bind searchText
                }
                centered - button {
                    spacing = 0.rem
                    icon { source = PIcon.close.copy(width = 1.rem, height = 1.rem) }
                    onClick { showSearch set !showSearch() }
                }
            }
        }
    }

    col {
        spacing = 0.dp
        row {
            dynamicTheme {
                ThemeDerivation {
                    it.copy(cornerRadii = CornerRadii.ForceConstant(0.dp)).withoutBack
                }
            }
            spacing = 0.dp
            expanding - col {
                spacing = 0.dp
                row {
                    expanding - button {
                        dynamicTheme {
                            if (displayMyColls()) {
                                ThemeDerivation {
                                    it.copy(
                                        background = Color.menuIconBg,
                                        foreground = Color.darkPurple
                                    ).withBack
                                }
                            } else null
                        }
                        centered - bold - text("My Collections")
                        onClick {
                            displayMyColls set true
                        }
                    }
                }
            }
            expanding - col {
                spacing = 0.dp
                row {
                    expanding - button {
                        dynamicTheme {
                            if (!displayMyColls()) {
                                ThemeDerivation {
                                    it.copy(background = Color.menuIconBg, foreground = Color.darkPurple).withBack
                                }
                            } else null
                        }
                        centered - bold - text("Shared With Me")
                        onClick {
                            displayMyColls set false
                        }
                    }
                }

            }
        }

        row {
            spacing = 0.dp
            compact - compact - compact - expanding - stack {
                dynamicTheme {
                    val bgColor = if (displayMyColls()) Color.darkPurple else Color.separatorColor
                    ThemeDerivation { it.copy(background = bgColor).withBack }
                }
            }

            compact - compact - compact - expanding - stack {
                dynamicTheme {
                    val bgColor = if (displayMyColls()) Color.separatorColor else Color.darkPurple
                    ThemeDerivation { it.copy(background = bgColor).withBack }
                }
            }
        }
    }


    val myCollectionsList = shared {
        session.awaitNotNull().collection2.listCollectionsLive().all().filter {
            ownsCollection(
                it,
                session.awaitNotNull().authenticatedUser.awaitNotNull().userId
            )
        }
    }
    val sharedWithMeCollections = shared {
        session.awaitNotNull().collection2.listCollectionsLive().all().filter {
            !ownsCollection(
                it,
                session.awaitNotNull().authenticatedUser.awaitNotNull().userId
            )
        }
    }

    val listedCollections = shared {
        (if (displayMyColls()) myCollectionsList()
        else sharedWithMeCollections()
                ).filter { it.collection.name.lowercase().contains(searchText().lowercase()) }
    }

    col {
        exists = false; ::exists { listedCollections().isEmpty() && searchText().isNotEmpty() }
        spacing = 12.rem
        space()
        centered - fadedForeground.onNext - subtext("No collections that match")
    }

    var recycler: RecyclerView?
    expanding - recyclerView {
        recycler = this
        exists = false; ::exists { listedCollections().isNotEmpty() }
        children(listedCollections) { coll ->
            unpadded - expanding - row {
                spacing = 0.dp
                space(multiplier = 0.5)
                unpadded - expanding - stack {
                    button {
                        dynamicTheme {
                            if (coll().collection == currentCollection()) ThemeDerivation {
                                it.copy(background = Color.menuIconBg).withBack
                            }
                            else null
                        }
                        val weAreOwner = shared {
                            ownsCollection(coll(), session.awaitNotNull().authenticatedUser.awaitNotNull().userId)
                        }
                        spacing = 0.25.rem
                        onClick {
                            onCollectionSelect()
                            val safeId = SafeIds.encode(coll().collection.collectionId.raw)
                            val screen = if (session.awaitNotNull().isVerifiedAccount()) CollectionDetail(safeId)
                            else PartialAuthUploadView(safeId)
                            mainScreenNavigator.navigate(screen)
                        }
                        row {
                            atTop - padded - stack {
                                spacing = 0.rem

                                val img = shared { coll().getCoverPhotoUri.imageIfExists() }

                                ThemeDerivation { it.copy(cornerRadii = CornerRadii.RatioOfSize(0.3f)).withBack }.onNext - centered - sizeConstraints(
                                    width = 2.rem,
                                    height = 2.rem
                                ) - image {
                                    exists = false
                                    ::exists { img() != null }
                                    ::source { img() }
                                    scaleType = ImageScaleType.Crop
                                }

                                centered - sizeConstraints(
                                    minWidth = 2.rem,
                                    minHeight = 2.rem
                                ) - unpadded - stack {
                                    sizeConstraints(height = 1.5.rem, width = 1.5.rem) - centered - icon {
                                        ::exists { img() == null }
                                        source = PIcon.image.copy(width = 1.rem, height = 1.rem)
                                        ::source {
                                            if (weAreOwner()) PIcon.image
                                            else PIcon.guests
                                        }
                                    }
                            }
                            }

                            expanding - centered - col {
                                spacing = 0.dp
                                h6 { ::content { coll().collection.name } }
                                fadedForeground.onNext - subtext {
                                    ::exists  { !weAreOwner() }
                                    ::content { coll().userParticipationRights.rightsOnCollection() }
                                }
                            }
                        }
                    }
                }
                space(multiplier = 0.5)
            }
        }
    }
    launch {
        searchText set ""
        val currentC = currentCollection() ?: return@launch

        myCollectionsList().firstOrNull { it.collection.collectionId == currentC.collectionId }?.let {
            displayMyColls.value = true
            val idx = myCollectionsList().indexOf(it)
            if (idx > 8) recycler?.scrollToIndex(idx)
            return@launch
        }
        sharedWithMeCollections().firstOrNull { it.collection.collectionId == currentC.collectionId }?.let {
            displayMyColls.value = false
            val idx = myCollectionsList().indexOf(it)
            if (idx > 8) recycler?.scrollToIndex(idx)
        }
    }
}